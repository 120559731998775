// app/connect-wallet/client.ts
import { createThirdwebClient, getContract } from "thirdweb";
import { chain } from '@/app/connect-wallet/chain';
import { defineChain } from 'thirdweb/chains';

const clientId = process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID!; // this will be used on the client
const secretKey = process.env.THIRDWEB_SECRET_KEY!; // this will be used on the server-side

export const client = createThirdwebClient(
  secretKey ? { secretKey } : { clientId },
);
// connect to your contract
const contract = getContract({ 
    client, 
    chain: defineChain(8453), 
    address: "0x3B2083e90506F4744F2850f7a1A2b36cBbfDEF22"
  });