
import { createPortal } from "react-dom";
import Image from "next/image";
import { useSelector } from "react-redux";
import { RootState } from "@utils/redux/store";
import { useHandleDisconnect } from "@app/connect-wallet/utils/hooks/useHandleDisconnect";
import { formatWalletAddress } from "./PersonalWalletButton";
import defaultImage from "../../public/assets/images/bamboo/game-balance.svg"
import { connectButtonTheme } from "@app/connect-wallet/theme";
import { useCallback, useEffect } from "react";

interface DisconnectModalProps {
    isOpened: boolean;
    avatarSrc: string;
    handleClose: () => void;
}

export const DisconnectModal = ({ isOpened, avatarSrc,  handleClose }: DisconnectModalProps) => {
  const { initialFetch, personalWalletAddress, balance: { personal: personalBalance} } = useSelector((state: RootState) => state.wallet);
  const { handleDisconnect } = useHandleDisconnect();

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if ((event.key === 'Escape' || event.key === 'Esc')) {
      handleClose()
    }
    }, [])


  useEffect(() => {
      if (isOpened) {
          document.addEventListener('keydown', handleEscape);
      }
      return () => {
          document.removeEventListener('keydown', handleEscape);
      }
  }, [isOpened])


  if (!isOpened) return null;

  return createPortal(
    <>
      <div className="popup-overlay" onClick={handleClose}></div>
      <div className="popup-content">

      <div className="disconnect-modal">
        <h4>Disconnect Wallet</h4>
        <div className="disconnect-modal-content">
          <div className="avatar-container">
            <Image
              alt="avatar"
              src={avatarSrc || defaultImage}
              className="wallet-avatar"
              width={46}
              height={46}
            />
          </div>
          <div className="wallet-info">
            <span className="wallet-address">{formatWalletAddress(personalWalletAddress ?? '')}</span>
            <span className="wallet-balance">{initialFetch ? "Loading..." : `${personalBalance} BAMBOO`}</span>
          </div>
        </div>

        <button
          onClick={handleDisconnect}
        >
          Disconnect
        </button>
        </div>
      </div>

      <style jsx>{`
        .disconnect-modal {
          background-color: ${connectButtonTheme.colors.modalBg};
          border: 1px solid ${connectButtonTheme.colors.borderColor};
          color: ${connectButtonTheme.colors.accentButtonText};
          border-radius: 20px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 250px;
          height: 320px;
          overflow: hidden;
        }

        .disconnect-modal h4 {
          font-weight: bold;
        }

        .disconnect-modal-content {
          display: flex;
          align-items: center;
        }

        .wallet-info {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding-left: 8px;
          font-size: 12px;
          color: rgb(255, 255, 255);
          line-height: normal;
          text-align: left;
          text-wrap: inherit;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0px;
          gap: 2px;
        }

        .wallet-address {
          font-weight: 500;
          display: block;
          max-width: 100%;
          overflow: hidden;
          font-size: 18px;
          line-height: 24px;
          white-space:nowrap;
          text-overflow: ellipsis;
        }

        .wallet-balance {
          font-weight: 400;
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .avatar-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0;
        }

        .wallet-avatar {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }

        .disconnect-modal button {
          width: 160px;
          height: 55px;
        }
      `}</style>
    </>,
    document.body
  );
};
