// components/sections/BambooTicker.tsx
"use client";

import React, { useEffect, useState, useRef } from 'react';

interface PairData {
  chainId: string;
  dexId: string;
  url: string;
  baseToken: { name: string; symbol: string };
  quoteToken: { symbol: string };
  priceUsd: string;
  priceChange: { h24: number };
  volume: { h24: number };
  txns: { h24: { buys: number; sells: number } };
  marketCap: number;
  fdv: number;
  liquidity: { usd: number };
}

const BambooTicker: React.FC = () => {
  const [pairs, setPairs] = useState<PairData[]>([]);
  const tickerRef = useRef<HTMLUListElement>(null);

  const fetchTickerData = async () => {
    try {
      const response = await fetch(
        'https://api.dexscreener.com/latest/dex/tokens/0x689644B86075ed61C647596862c7403e1c474dbf'
      );
      const data = await response.json();
      if (data && data.pairs) {
        setPairs(
          data.pairs.map((pair: any) => ({
            chainId: pair.chainId,
            dexId: pair.dexId,
            url: pair.url,
            baseToken: pair.baseToken,
            quoteToken: pair.quoteToken,
            priceUsd: pair.priceUsd,
            priceChange: pair.priceChange,
            volume: pair.volume,
            txns: pair.txns,
            marketCap: pair.marketCap,
            fdv: pair.fdv,
            liquidity: pair.liquidity,
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching ticker data:', error);
    }
  };

  useEffect(() => {
    fetchTickerData(); // Initial fetch on component mount

    // Set interval to refresh data every 60 seconds
    const intervalId = setInterval(fetchTickerData, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Clone ticker items once on mount for infinite scrolling
  useEffect(() => {
    if (tickerRef.current && pairs.length > 0) {
      const ticks = tickerRef.current;
      const tickItems = Array.from(ticks.children);

      // Only clone items if clones do not already exist
      if (tickItems.length <= pairs.length) {
        const tickNum = 3; // Number of times to clone items
        for (let i = 0; i < tickNum; i++) {
          tickItems.forEach((tickItem) => {
            const clone = tickItem.cloneNode(true) as HTMLLIElement;
            ticks.appendChild(clone);
          });
        }

        // Force a reflow to ensure the animation starts immediately on mobile
        const ticker = document.querySelector('.ticker ul') as HTMLElement;
        if (ticker) {
          ticker.style.animation = 'none';
          requestAnimationFrame(() => {
            ticker.style.animation = '';
          });
        }
      }
    }
  }, [pairs.length]); // Only run this effect once when initial data is loaded

  return (
    <div className="ticker">
      <div className="ticker-content">
        <ul ref={tickerRef}>
          {pairs.map((pair, index) => (
            <li key={index}>
              <a href={pair.url} target="_blank" rel="noopener noreferrer">
                <b>{pair.baseToken.symbol}/{pair.quoteToken.symbol}</b>
                <span> ${pair.priceUsd}</span>
                <svg
                  className={pair.priceChange.h24 >= 0 ? 'up' : 'down'}
                  x="0px"
                  y="0px"
                  viewBox="0 0 20 10"
                  xmlSpace="preserve"
                >
                  <path d={pair.priceChange.h24 >= 0 ? 'M10,0l10,10H0L10,0z' : 'M10,10L0,0h20L10,10z'} />
                </svg>
                <span> {pair.priceChange.h24 >= 0 ? '+' : ''}{pair.priceChange.h24}%</span>
                <span>24hr Vol: ${pair.volume.h24.toLocaleString()}</span>
                <span>Market Cap: ${pair.marketCap.toLocaleString()}</span>
                <span>Liquidity: ${pair.liquidity.usd.toLocaleString()}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BambooTicker;
