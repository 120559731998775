import { useEffect, FunctionComponent, useRef } from "react";
import { ConnectButton, useConnectedWallets } from "thirdweb/react";
import { createWallet, SmartWalletOptions } from 'thirdweb/wallets';
import { ACCOUNT_FACTORY_ADDRESS } from "@/app/connect-wallet/constants";
import { chain } from "@/app/connect-wallet/chain";
import { client } from "@/app/connect-wallet/client";
import { connectButtonTheme } from "@/app/connect-wallet/theme";
import { supportedTokens } from "@/app/connect-wallet/supportedTokens";
import { connectModal } from "@/app/connect-wallet/connectModal";
import { appMetadata } from "@/app/connect-wallet/appMetadata";
import { detailsModal } from "@/app/connect-wallet/detailsModal";

// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "@/redux/store";
import { setSmartWalletAddress } from '@/store/slices/walletSlice';

// Utility imports
import { fetchBalances } from '@/app/connect-wallet/utils/balanceFetcherManager';
import { useHandleDisconnect } from "@app/connect-wallet/utils/hooks/useHandleDisconnect";
import { useWalletConnect } from "@app/connect-wallet/utils/hooks/useWalletConnect";
import { useSessionKeyManager } from "@app/connect-wallet/utils/hooks/useSessionKeyManager";
import { useSendEmptyTransaction } from "@app/connect-wallet/utils/hooks/useSendEmptyTransaction";
import { useShouldSendEmptyTransaction } from "@app/connect-wallet/utils/hooks/useShouldSendEmptyTransaction";
import { useWalletAssignment } from "@app/connect-wallet/utils/hooks/useWalletAssignment";

import GameWalletButton from "./GameWalletButton";

const smartWalletConfig: SmartWalletOptions = {
  factoryAddress: ACCOUNT_FACTORY_ADDRESS,
  chain,
  sponsorGas: true,
};

const wallets = [  createWallet("finance.openwallet"),
  createWallet("io.metamask"),
  createWallet("walletConnect"),
  createWallet("com.coinbase.wallet"),
  createWallet("io.zerion.wallet"),
  createWallet("com.ambire"),
  createWallet("me.rainbow"),
  createWallet("com.bitget.web3"),
  createWallet("com.brave.wallet"),
  createWallet("com.exodus")];

export const ConnectTWButton: FunctionComponent = () => {
  const dispatch = useDispatch();
  const connectedWallets = useConnectedWallets();

  // Handle disconnecting wallets
  const { handleDisconnect } = useHandleDisconnect();
  const { sendEmptyTransaction } = useSendEmptyTransaction();
  const { connectWallet } = useWalletConnect();
  const { checkExistingSessionKey } = useSessionKeyManager();
  const { isRegistered, isFetched } = useShouldSendEmptyTransaction();
  const { assignWallets } = useWalletAssignment();


  // Redux selectors
  const { smartWalletAddress, personalWalletAddress, hasValidSessionKey, initialFetch } = useSelector((state: RootState) => (state.wallet));
  const token = useSelector((state: RootState) => state.auth.token);

  const fetchInProgress = useRef(false); 

  /**
   * Handles the connection process when a wallet is connected.
   * This function retrieves the smart wallet address and initiates
   * the session key creation process if applicable.
   * @param event The event object containing wallet connection information.
   */
  const handleConnect = async (event: any) => {
    try {
      const smartWalletInstance = event;
      const smartWalletAddr = await smartWalletInstance.getAccount();

      if (smartWalletAddr?.address) {
        dispatch(setSmartWalletAddress(smartWalletAddr.address));
      }

    } catch (error) {
      console.error("Error during wallet connection:", error);
    }
  };

  // First step: Handle connecting the wallet and triggering the empty transaction
  useEffect(() => {
    if (connectedWallets.length > 0 && smartWalletAddress && isFetched) { 
      if (isRegistered === true) {
        assignWallets()
      } else {
        sendEmptyTransaction(); 
      }
    }
  }, [connectedWallets, smartWalletAddress, isRegistered, isFetched, dispatch]);

  // Second step: Handle the rest of the onConnect logic after the empty transaction has been sent and wallets have been assigned
   useEffect(() => {
    if (personalWalletAddress && smartWalletAddress) {
      connectWallet();
    }
  }, [smartWalletAddress, personalWalletAddress]);

  // Poll for balances every 15 seconds, this ensures that if bet is placed, it will be gone through the blockchain and ensure the correct values.
  useEffect(() => {
    let pingInterval: NodeJS.Timeout | undefined;
  
    if (token && personalWalletAddress && smartWalletAddress && hasValidSessionKey) {
      // Determine interval based on initialFetch
      const intervalTime = initialFetch ? 1000 : 15 * 1000;
  
      pingInterval = setInterval(async () => {
        if (fetchInProgress.current) return;  // Skip if fetch is already in progress
  
        fetchInProgress.current = true;  // Mark fetch as in progress
        try {
          await fetchBalances(smartWalletAddress, personalWalletAddress, token, dispatch);
        } catch (error) {
          console.error("Error fetching balances:", error);
        } finally {
          fetchInProgress.current = false;  // Reset flag after fetch completes
        }
      }, intervalTime);
    }
  
    return () => {
      if (pingInterval) clearInterval(pingInterval);
    };
  }, [dispatch, personalWalletAddress, smartWalletAddress, token, hasValidSessionKey, initialFetch]);
  

  // Poll for session key validity every 1 hour
  useEffect(() => {
    let sessionKeyInterval: NodeJS.Timeout | undefined;

    if (smartWalletAddress) {
      // Start polling regardless of session key validity state
      sessionKeyInterval = setInterval(async () => {
        await checkExistingSessionKey(); // Will set the validity in Redux
      }, 60 * 60 * 1000);
    }

    return () => {
      if (sessionKeyInterval) clearInterval(sessionKeyInterval);
    };
  }, [smartWalletAddress, chain, dispatch]);

  return (
    <div className="connect-button">
      <ConnectButton
        client={client}
        chain={chain}
        wallets={wallets}
        showAllWallets={false}
        theme={connectButtonTheme}
        accountAbstraction={smartWalletConfig}
        supportedTokens={supportedTokens}
        connectModal={connectModal}
        appMetadata={{
          name: "PandaPlinko.io",
          url: "https://pandaplinko.io",
          description: "The home of betting on $BAMBOO",
          logoUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/32180.png",
        }}
        detailsButton={{
          render() {
            return <GameWalletButton />
           }
        }}
        detailsModal={detailsModal}
        onConnect={handleConnect}
        onDisconnect={handleDisconnect}
      />
    </div>
  );
};