'use client';
import React, { useEffect, useState } from 'react';
import BackToTop from '../elements/BackToTop';
import Breadcrumb from './Breadcrumb';
import Footer1 from './footer/Footer1';
import Header1 from './header/Header1';
import '../../public/assets/css/style.css';
import '../../public/assets/css/shortcodes.css';
import '../../public/assets/css/responsive.css';
import { useDispatch } from 'react-redux';
import { setIsMobile } from '@/store/slices/uiSlice';

interface LayoutProps {
  breadcrumbTitle?: string;
  children?: React.ReactNode;
  footercls?: string;
}

export default function Layout({ breadcrumbTitle, children, footercls }: LayoutProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobileDevice = /android|iphone|ipad|ipod|mobile/i.test(userAgent) || window.innerWidth <= 768;
      dispatch(setIsMobile(isMobileDevice));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  return (
    <>
      {/* Background container */}
      <div className="background-container" />

      <div id="top" />
      <div id="wrapper">
        <div id="page" className="clearfix">
          <Header1/>
          <main className="main" style={{ paddingTop: '150px' }}>
            {breadcrumbTitle && <Breadcrumb breadcrumbTitle={breadcrumbTitle} />}
            {children}
          </main>
          <Footer1 footercls={footercls} />
        </div>
      </div>

      <BackToTop target="#top"/>
    </>
  );
}
