import React from 'react';
import Image from 'next/image';
import { ConnectTWButton } from '@components/elements/ConnectButton';

const LoggedOut: React.FC = () => {

  return (
    <div className="logged-out-container">
      {/* Logo and Icon Lockup */}
      <div className="logo-lockup">
        {/* Sunburst Background */}
        <div className="sunburst-wrapper">
          <Image
            src="/assets/images/sunburst/sunburst.svg"
            alt="Sunburst Background"
            layout="intrinsic"
            width={1200}
            height={1200}
            className="sunburst"
          />
        </div>

        <div className="icon-wrapper">
          {/* GIF Icon */}
          <Image
            src="/assets/images/bigp/bigp-wink.gif"
            alt="Big Panda Wink"
            layout="intrinsic"
            width={375}  // Initial width, responsive with CSS
            height={375}
            className="icon"
            unoptimized
          />
        </div>

        <div className="logo-wrapper">
          {/* SVG Logo */}
          <Image
            src="/assets/images/logo/pp-logo.svg"
            alt="Panda Plinko Logo"
            layout="intrinsic"
            width={700}  // Initial width, responsive with CSS
            height={295}
            className="logo"
          />
        </div>
      </div>

      {/* Description Text */}
      <h5 className="description-text">
        Drop the ball and watch as it bounces through the board. Aim for the highest reward!
      </h5>

      {/* Connect Wallet Button */}
      <div className="connect-wallet-button">
        <ConnectTWButton />
      </div>
    </div>
  );
};

export default LoggedOut;
