import { TOKEN_BAMBOO } from "@app/constants/tokens";
import { setRefetchGameState } from "@utils/redux/slices/uiSlice";
import { RootState } from "@utils/redux/store";
import Image from "next/image";
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface TransferInputProps {
  onTransfer: (amount: string) => void,
  onSuccess: () => void,
  type: 'out' | 'in',
  errorMessage: string;
  isLoading: boolean;
  isSuccess: boolean;
}

export const TransferInput = ({
  onTransfer,
  type,
  onSuccess,
  errorMessage,
  isLoading,
  isSuccess
}: TransferInputProps) => {
  const { balance: { personal, smart } } = useSelector((state: RootState) => state.wallet);
  const [value, setValue] = useState("");
  const refInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      // re-render game iframe to update game balance
      dispatch(setRefetchGameState(true));
    }
  }, [isSuccess])

  const imageSrc = useMemo(() => {
    if (type === 'in' && isLoading) {
      return '/assets/images/cashier/transferring-to-game-balance.svg';
    }
    if (type === 'in') {
      return '/assets/images/cashier/transfer-to-game-balance.svg';
    }
    if (isLoading) {
      return '/assets/images/cashier/transferring-to-wallet.svg';
    }
    return '/assets/images/cashier/cashout-to-wallet.svg';
  }, [isLoading, type]);
  

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(Number(newValue) < 0 ? "0" : newValue);
  }, []);

  const handleOnMax = useCallback(() => {
    setValue((type === 'in' ? personal : smart).toString())
    refInput.current?.focus();
  }, [personal, smart, type])

  const handleOnSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onTransfer(value)
  }, [value])

  useEffect(() => {
    refInput.current?.focus();
  }, [])

  return (
    <form
      className="transfer-input-container"
      onSubmit={handleOnSubmit}
      noValidate
    >
      {errorMessage &&
        <span
          style={{
            color: 'rgb(255, 51, 51)'
          }}
        >
          {errorMessage}
        </span>
      }
      <label className="transfer-input-label">
        <div className="input-container">
          <input
            ref={refInput}
            type="number"
            value={value}
            min={0}
            placeholder="Input amount"
            onChange={handleChange}
            className="transfer-input"
            style={{
              padding: 0,
              border: 'none'
            }}
          />
          <button
            className="transfer-input-max"
            type="button"
            onClick={handleOnMax}
            style={{
              padding: "2px 10px",
              borderRadius: '16px',
              borderColor: '#4B4B4B',
              background: '#00000033',
              flexShrink: 0,
            }}
          >
            Max
          </button>
        </div>
        <span>{TOKEN_BAMBOO.symbol}</span>
      </label>

      <button
        type="submit"
        disabled={isLoading}
        className={`transfer-input-container-button ${isLoading ? 'no-hover-focus' : ''}`}
        style={{
          padding: 0,
          background: 'transparent',
          border: 'none !important'
        }}
      >
        <Image
          src={imageSrc}
          width={342}
          height={33}
          alt="transfer_button"
          loading="eager"
        />
      </button>
    </form>
  );
}
