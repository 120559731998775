import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { useConnectedWallets, useWaitForReceipt } from "thirdweb/react";
import { TransferInput } from "./TransferInput";
import { useSelector } from "react-redux";
import { RootState } from "@utils/redux/store";
import { createPortal } from 'react-dom';
import { prepareContractCall, sendTransaction, toWei } from "thirdweb";
import { TOKEN_BAMBOO } from "@app/constants/tokens";
import { switchToChainId } from "@utils/ethers";
import { client } from "@app/connect-wallet/client";
import { base } from "thirdweb/chains";

export const CashOutButton = () => {
    const [isOpened, setIsOpened] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const connectedWallets = useConnectedWallets();
    const smartWallet = connectedWallets?.[1]
    const personalWalletAddress = useSelector((state: RootState) => state.wallet.personalWalletAddress);
    const [transactionHash, setTransactionHash] = useState('');


    const {
        isSuccess,
        isLoading,
    } = useWaitForReceipt({
        transactionHash: transactionHash as `0x${string}`,
        chain: base,
        client: client
    });

    const transferOut = useCallback(async (amount: string) => {
        setErrorMessage('')
        if (personalWalletAddress) {
            await switchToChainId(TOKEN_BAMBOO.chainId);
            try {
                const transaction = prepareContractCall({
                    contract: TOKEN_BAMBOO.contract,
                    method: "function transfer(address to, uint256 value)",
                    params: [personalWalletAddress, toWei(amount)],
                });

                sendTransaction({
                    account: smartWallet.getAccount()!,
                    transaction,
                }).then(result => {
                    setTransactionHash(result.transactionHash)
                }).catch((err) => {
                    console.log(err?.code);

                    setErrorMessage(err?.code === 4001 ? "Transaction rejected" : 'Insufficient balance')
                    setTransactionHash('')
                })

                setTransactionHash('loading')

            } catch (error) {
                setTransactionHash('')
                console.error("error", error);
            }
        }
    }, [
        personalWalletAddress,
        connectedWallets
    ])

    const handleClose = useCallback(() => {
        if (!isLoading) {
            setIsOpened(false)
            setErrorMessage('')
        }
    }, [isLoading])

    const handleEscape = useCallback((event: KeyboardEvent) => {
        if ((event.key === 'Escape' || event.key === 'Esc') && !isLoading) {
            setIsOpened(false)
            setErrorMessage('')
        }
    }, [isLoading])

    useEffect(() => {
        if (isOpened) {
            document.addEventListener('keydown', handleEscape);
        }
        return () => {
            document.removeEventListener('keydown', handleEscape);
        }
    }, [
        isOpened,
        isLoading
    ])

    return (
        <div style={{
            position: 'relative',
            zIndex: isOpened ? 1 : 0
        }}>
            <button
                style={{
                    padding: 0,
                    border: 'none',
                    background: 'transparent'
                }}
                type="button"
                onClick={() => setIsOpened(true)}
            >
                <Image src={"/assets/images/cashier/cash-out.svg"}
                    width={140}
                    height={30}
                    alt='cash_out'
                    style={{
                        maxWidth: '138px'
                    }}
                    loading="eager"
                />
            </button>

            {
                isOpened &&
                createPortal(
                    <>
                        <div className="popup-overlay" onClick={handleClose}></div>
                        <div className="popup-content">
                            <TransferInput
                                onTransfer={transferOut}
                                isLoading={isLoading}
                                isSuccess={isSuccess}
                                errorMessage={errorMessage}
                                type="out"
                                onSuccess={() => {
                                    setIsOpened(false)
                                    setTransactionHash('')
                                    setErrorMessage('')
                                }}
                            />
                        </div>
                    </>,
                    document.body
                )
            }
        </div>
    )
}
