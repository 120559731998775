'use client'

import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

interface Win {
  username: string;
  bet: number;
  win_value: number;
  multiplier: string;
  created_at: string;
}

const WinsDisplay: React.FC = () => {
  const [wins, setWins] = useState<Win[]>([]); // Specify the type for wins

  useEffect(() => {
    const socket = io('ws://localhost:3005'); // Replace with your actual WebSocket server URL

    socket.on('winsUpdate', (newWin: Win) => {
      setWins((prevWins) => [newWin, ...prevWins.slice(0, 9)]); // Limit to the last 10 wins
    });

    return () => {
      socket.disconnect(); // Clean up on component unmount
    };
  }, []);

  return (
    <div className="container">
      <div className="win-display-table-wrapper">
        <table className="win-display-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Bet</th>
              <th>Win</th>
              <th>Multiplier</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {wins.map((win, index) => (
              <tr key={index} className="win-display-row">
                <td>{win.username}</td>
                <td>{win.bet}</td>
                <td>{win.win_value}</td>
                <td>{win.multiplier}x</td>
                <td>{new Date(win.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WinsDisplay;
