import { useEffect, useState } from "react";

/**
 * Custom hook to detect the user's operating system (OS).
 * - Determines the OS based on the `navigator.platform` property of the browser.
 * - Supports detection of macOS, Windows, iOS, and Android platforms.
 *
 * @returns {String} - A string representing the detected OS, either 'apple', 'windows', or 'android'.
 */
const useDetectOS = () => {
    const [currentOS, setCurrentOs] = useState<String>("");

    const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    useEffect(() => {
        const platform = window.navigator.platform;

        const detectedOs = 
            macosPlatforms.includes(platform) || iosPlatforms.includes(platform) ? 'apple' 
            : windowsPlatforms.includes(platform) ? 'windows' 
            : 'android';

        setCurrentOs(detectedOs);
    }, []);

    return currentOS;
};

export default useDetectOS;
