// balanceFetcher.ts
import { setBalance, setInitialFetch } from '@/store/slices/walletSlice';

export const fetchBalances = async (smartWalletAddress: string, personalWalletAddress: string, token: string, dispatch: any) => {
    try {
      const response = await fetch('/api/general/user/balanceFront', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ smartWalletAddress, personalWalletAddress }),
      });
  
      const data = await response.json();
      if (!data.error) {
        dispatch(setBalance({
          personal: parseFloat(data.personalBalance),
          smart: parseFloat(data.smartBalance),
          smartBamboo: parseFloat(data.smartBambooBalance),
          personalBamboo: parseFloat(data.personalBambooBalance),
        }));
      }

      dispatch(setInitialFetch(false));

    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };
  