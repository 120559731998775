import { darkTheme } from "thirdweb/react"; // Or use lightTheme if you prefer

// Theme for the Connect Button
export const connectButtonTheme = darkTheme({
  colors: {
    accentButtonBg: "#32CD32", // Lime Green for buttons
    accentButtonText: "#FFFFFF", // White for button text
    accentText: "#FFFFFF", // White for accent text
    borderColor: "#006400", // Forest Green for borders
    connectedButtonBg: "#000000",
    connectedButtonBgHover: "#111111", // Green for hover
    danger: "#8B0000", // Dark Red for danger
    modalBg: "radial-gradient(circle, #166552FF 0%, #002f24FF 65%, #002f24FF 100%)",
    modalOverlayBg: "rgba(0, 0, 0, 0.15)",
    primaryButtonBg: "#5B8A33", // Lime Green for primary button
    primaryButtonText: "#FFFFFF", // White for primary button text
    primaryText: "#FFFFFF", // White for primary text
    scrollbarBg: "#98FB98", // Pale Green for scrollbar
    secondaryButtonBg: "#5B8A33", // Forest Green for secondary button
    secondaryButtonHoverBg: "#111111", // Dark Green for hover
    secondaryButtonText: "#FFFFFF", // White for secondary button
    secondaryIconColor: "#32CD32", // Lime Green for icons
    secondaryIconHoverBg: "#228B22", // Forest Green for hover
    secondaryIconHoverColor: "#FFFFFF", // White for hover icon
    secondaryText: "#FFFFFF", // White for secondary text
    selectedTextBg: "#006400", // Dark Green for selected text
    selectedTextColor: "#FFFFFF", // White for selected text
    separatorLine: "#2E8B57", // SeaGreen for separator
    skeletonBg: "#98FB98", // Pale Green for skeleton loading
    success: "#00FF7F", // Spring Green for success
    tertiaryBg: "#006400", // Dark Green for tertiary background
    tooltipBg: "#2E8B57", // SeaGreen for tooltips
    tooltipText: "#FFFFFF", // White for tooltip text
  },
  fontFamily: "'font-azo-light', sans-serif", // Explicitly set Azo Sans Web directly
});

// Theme for the Details Modal
export const detailsModalTheme = {
  colors: {
    modalBg: "#228B22", // Use a solid color for example
    accentButtonBg: "#32CD32", // Lime Green
    accentButtonText: "#FFFFFF", // White
    accentText: "#FFFFFF", // White
    borderColor: "#006400", // Dark Green
    primaryButtonBg: "#32CD32", // Lime Green
    primaryButtonText: "#FFFFFF", // White
    secondaryButtonBg: "#228B22", // Forest Green
    secondaryButtonText: "#FFFFFF", // White
    tooltipBg: "#2E8B57", // SeaGreen for tooltip background
    tooltipText: "#FFFFFF", // White for tooltip text
  },
  fontFamily: "'azo-sans-web', sans-serif", // Use Azo Sans Web for the Details Modal
  type: "dark", // or "light"
};

// Theme for the Connect Modal
export const connectModalTheme = {
  colors: {
    modalBg: "#2E8B57", // SeaGreen for modal background
    primaryText: "#FFFFFF", // White for primary text
    secondaryText: "#DCDCDC", // Light Gray for secondary text
    success: "#32CD32", // Lime Green for success
    danger: "#8B0000", // Dark Red for danger
    separatorLine: "#228B22", // Forest Green for separator line
    tooltipBg: "#006400", // Dark Green for tooltip background
    tooltipText: "#FFFFFF", // White for tooltip text
  },
  fontFamily: "'azo-sans-web', sans-serif", // Use Azo Sans Web for the Connect Modal
  type: "light", // or "dark"
};
