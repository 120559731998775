import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/pplinko/pandaplinko-main/components/layout/Layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/pplinko/pandaplinko-main/components/sections/BambooDataStrip.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/pplinko/pandaplinko-main/components/sections/Category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/pplinko/pandaplinko-main/components/sections/GameFrame.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/pplinko/pandaplinko-main/components/sections/Slider1.js");
;
import(/* webpackMode: "eager" */ "/var/www/pplinko/pandaplinko-main/components/sections/WinsDisplay.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/pplinko/pandaplinko-main/node_modules/next/dist/client/image-component.js");
