import { useDispatch, useSelector } from "react-redux";
import { useSessionKeyManager } from "./useSessionKeyManager";
import { RootState } from "@utils/redux/store";
import { setToken } from "@utils/redux/slices/authSlice";
import { setIsLoggedIn } from "@utils/redux/slices/uiSlice";
import { useConnectedWallets } from "thirdweb/react";


export const useWalletConnect = () => {
  const dispatch = useDispatch();
  const connectedWallets = useConnectedWallets();
  const { handleSessionKeyCreation  } = useSessionKeyManager();
  const { smartWalletAddress, personalWalletAddress } = useSelector((state: RootState) => (state.wallet));
 
  const connectWallet = async () => {
    if (!connectedWallets || connectedWallets.length === 0) return;
  
    try {
      const resolvedWallets = await Promise.all(
        connectedWallets.map(async (wallet) => {
          const account = wallet.getAccount();
          return {
            walletType: wallet.id,
            walletAddress: account ? account.address : "Address not available",
          };
        })
      );
  
      const res = await fetch('/api/general/login/onConnect', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          personalWallet: personalWalletAddress,
          smartWalletAddress,
          connectedWallets: resolvedWallets,
        }),
      });
  
      const data = await res.json();
      if (data.token) {
        dispatch(setToken(data.token));
        dispatch(setIsLoggedIn(true));
        await handleSessionKeyCreation();
      }
    } catch (error) {
      console.error('Error setting logged-in status:', error);
    }
  };

  return { connectWallet }
}

  