import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { connectButtonTheme } from "@app/connect-wallet/theme";
import Image from 'next/image';

import gameWalletImg from "../../public/assets/images/bamboo/game-balance.svg"

const GameWalletButton: React.FC = () => {
    const { initialFetch, balance: { smart: smartBalance } } = useSelector((state: RootState) => state.wallet);

    return (
        <div className="wallet-display">
            <button className="wallet-button">
                <div className="avatar-container">
                    {gameWalletImg && (
                        <Image
                            src={gameWalletImg}
                            alt="avatar"
                            width={46}
                            height={46}
                            style={{
                                borderRadius: '100%',
                            }}
                        />
                    )}
                </div>
                <div className="wallet-info">
                    <span className="wallet-name">Game Balance</span>
                    <span className="wallet-balance">{initialFetch ? "Loading..." : `${smartBalance} BAMBOO`}</span>
                </div>
            </button>

            <style jsx>{`
        .wallet-button {
          background-color: ${connectButtonTheme.colors.connectedButtonBg};
          border: none;
          color: ${connectButtonTheme.colors.accentButtonText};
          border-radius: 8px;
          padding: 8px;
          display: flex;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
          width: 190px;
          transition: background-color 0.3s ease;
          overflow: hidden;
        }
        .wallet-button:hover {
          background-color: ${connectButtonTheme.colors.connectedButtonBgHover};
        }
        .wallet-info {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding-left: 8px;
          font-size: 12px;
          color: rgb(255, 255, 255);
          line-height: normal;
          text-align: left;
          text-wrap: inherit;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0px;
          gap: 2px;
        }
        .wallet-name {
          font-weight: 500;
          display: block;
          max-width: 100%;
          overflow: hidden;
          font-size: 18px;
          line-height: 24px;
          text-overflow: ellipsis;
          white-space:nowrap;
        }
        .wallet-balance {
          font-weight: 400;
          display: block;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .avatar-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          overflow: hidden;
          flex-shrink: 0;
        }
      `}</style>
        </div>
    );
};

export default GameWalletButton;