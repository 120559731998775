// components/elements/gameframe/SigningKey.tsx
import React from 'react';

interface SigningKeyProps {
  isSigning: boolean;
}

const SigningKey: React.FC<SigningKeyProps> = ({ isSigning }) => {
  return (
    <div className="new-letter-inner style-2 flex wow">
      <div className="overlay">
        <h2>Session Key Creation</h2>
        <p>
          {isSigning
            ? 'Please wait until we verify your connected wallet...'
            : 'Creating session key...'}
        </p>
      </div>
    </div>
  );
};

export default SigningKey;
