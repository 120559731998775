import React, { useEffect, useState } from 'react';

interface GameLauncherProps {
  iframeSrc: string;
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

const GameLauncher: React.FC<GameLauncherProps> = ({ iframeSrc, isFullscreen, toggleFullscreen, containerRef }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect whether the user is on a mobile device
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider it mobile if the width is <= 768px
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Update on resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`iframe-container ${isFullscreen ? 'fullscreen-modal' : ''}`}
      style={{
        ...(isFullscreen
          ? {}
          : { aspectRatio: isMobile ? '9 / 16' : '16 / 9' }
        ),
        maxWidth: isFullscreen ? '100vw' : '70vw',
        maxHeight: isFullscreen ? '100vh' : '70vh',
        height: 'auto',
        borderRadius: isFullscreen ? '0px' : '30px',
        overflow: 'hidden',
        position: isFullscreen ? 'fixed' : 'relative',
        top: isFullscreen ? '50%' : 'auto',
        left: isFullscreen ? '50%' : 'auto',
        transform: isFullscreen ? 'translate(-50%, -50%)' : 'none',
        zIndex: isFullscreen ? 10000 : 'auto',
      }}
    >
    
      <iframe
        frameBorder="0"
        className="iframeGame"
        id="iframeGameDesktop"
        src={iframeSrc}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: isFullscreen ? '30px' : 'inherit',
        }} 
      ></iframe>

      <div className="fullscreen-controls">
        <div className="labels">
          <span className={`label ${!isFullscreen ? 'active' : ''}`}>Framed</span>
          <label className="switch">
            <input type="checkbox" checked={isFullscreen} onChange={toggleFullscreen} />
            <span className="slider round"></span>
          </label>
          <span className={`label ${isFullscreen ? 'active' : ''}`}>Full</span>
        </div>
      </div>
    </div>
  );
};

export default GameLauncher;
