import { TOKEN_BAMBOO } from "@app/constants/tokens";
import { LiFiWidget, WidgetDrawer } from "@lifi/widget"
import Image from "next/image";
import { Fragment, useRef } from "react"

export const SwapWidget = () => {
    const drawerRef = useRef<WidgetDrawer>(null);

    const onClick = () => {
        drawerRef.current?.toggleDrawer()
    }

    return (
        <Fragment>
            <button className="buy-token-button" type="button" onClick={onClick}>
                <span>
                    Buy Bamboo 
                </span>
                <Image src={"/assets/images/logo/lifi-logo.svg"} width={16} height={22} alt="lifi_logo"/>
            </button>

            <LiFiWidget
                ref={drawerRef}
                integrator="Panda_Plinko"
                config={
                    {
                        tokens: {
                            featured: [
                                {
                                    address: TOKEN_BAMBOO.address,
                                    chainId: TOKEN_BAMBOO.chainId,
                                    decimals: TOKEN_BAMBOO.decimals,
                                    name: TOKEN_BAMBOO.name,
                                    symbol: TOKEN_BAMBOO.symbol,
                                    logoURI: TOKEN_BAMBOO.logoURI
                                }
                            ],
                        },
                        chains: {
                            allow: [TOKEN_BAMBOO.chainId]
                        },
                        appearance: 'dark',
                        fromChain: TOKEN_BAMBOO.chainId,
                        toChain: TOKEN_BAMBOO.chainId,
                        variant: 'drawer', // turn the widget into a drawer
                        fromToken: '0x0000000000000000000000000000000000000000', // ETH Address in lifi widget
                        toToken: TOKEN_BAMBOO.address,
                        hiddenUI: ['appearance', 'history'],
                        // Customization guide => https://docs.li.fi/integrate-li.fi-widget/customize-widget#palette-shape-typography
                        theme: {
                            palette: {
                              primary: { main: '#32CD32' }
                            },
                            shape: {
                              borderRadius: 0,
                              borderRadiusSecondary: 0,
                            },
                            typography: {
                                htmlFontSize : 12
                            },
                          },
                    }
                }
            />
        </Fragment>
    )
}