export const supportedTokens = {
    "8453": [
      {
        address: '0x689644b86075ed61c647596862c7403e1c474dbf',
        name: 'Bamboo on Base',
        symbol: 'BAMBOO',
        icon: 'https://assets.coingecko.com/coins/images/39126/standard/Bamboo_Logo_800x800.png?1720656497',
      },
    ],
  };
  