import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@utils/redux/store';
import { resetAuth } from '@/store/slices/authSlice';
import { resetWallet } from '@/store/slices/walletSlice';
import { resetUI, setIsDisconnecting } from '@/store/slices/uiSlice';
import { useConnectedWallets } from 'thirdweb/react';

/**
 * Custom hook for handling wallet disconnection.
 * - Disconnects all connected wallets except the smart wallet.
 * - Notifies the server of the disconnection and clears the Redux store for authentication, wallet, and UI state.
 *
 * @returns {Object} - Contains the `handleDisconnect` function for managing wallet disconnection.
 */
export const useHandleDisconnect = () => {
  const dispatch = useDispatch();
  
  const token = useSelector((state: RootState) => state.auth.token);

  const connectedWallets = useConnectedWallets();

  /**
   * Function to handle the disconnection of all connected wallets.
   * - Dispatches a loading state (`setIsDisconnecting`) to indicate that the disconnection process has started.
   * - Iterates through each connected wallet to disconnect and notify the server of the disconnection.
   * - Resets the Redux states for authentication, wallet, and UI after disconnection.
   */
  const handleDisconnect = useCallback(async () => {
    if (!connectedWallets || connectedWallets.length === 0) return;

    try {
      dispatch(setIsDisconnecting(true));

      for (const wallet of connectedWallets) {
        const account = await wallet.getAccount();

        if (account && account.address) {
          await fetch('/api/general/login/onDisconnect', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ smartWalletAddress: account.address }),
          });
        }
        await wallet.disconnect();
        localStorage.removeItem('thirdweb:connected-wallet-ids');
      }

      dispatch(resetAuth());
      dispatch(resetWallet());
      dispatch(resetUI());
      
    } catch (error) {
      console.error("Error during wallet disconnection:", error);
    } finally {
      // Ensure the disconnecting state is reset
      dispatch(setIsDisconnecting(false));
    }
  }, [connectedWallets, dispatch, token]);

  return { handleDisconnect };
};