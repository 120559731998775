import Image from "next/image"
import { TransferInput } from "./TransferInput";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@utils/redux/store";
import { createPortal } from 'react-dom';
import { prepareContractCall, sendTransaction, toWei } from "thirdweb";
import { useConnectedWallets, useWaitForReceipt } from "thirdweb/react";
import { TOKEN_BAMBOO } from "@app/constants/tokens";
import { switchToChainId } from "@utils/ethers";
import { base } from "thirdweb/chains";
import { client } from "@app/connect-wallet/client";

export const TransferInButton = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const connectedWallets = useConnectedWallets();
  const personalWallet = connectedWallets?.[0]
  const smartWalletAddress = useSelector((state: RootState) => state.wallet.smartWalletAddress);

  const {
    isSuccess,
    isLoading,
  } = useWaitForReceipt({
    transactionHash: transactionHash as `0x${string}`,
    chain: base,
    client: client
  });

  const transferIn = useCallback(async (amount: string) => {
    setErrorMessage('')
    if (smartWalletAddress) {
      await switchToChainId(TOKEN_BAMBOO.chainId);
      try {
        const transaction = prepareContractCall({
          contract: TOKEN_BAMBOO.contract,
          method: "function transfer(address to, uint256 value)",
          params: [smartWalletAddress, toWei(amount)],
        });

        sendTransaction({
          account: personalWallet?.getAccount()!,
          transaction,
        }).then(result => {
          setTransactionHash(result.transactionHash)
        }).catch((err) => {
          setErrorMessage(err?.code === 4001 ? "Transaction rejected" : 'Insufficient balance')
          setTransactionHash('')
        });

        setTransactionHash('loading')

      } catch (error) {
        setTransactionHash('')
        console.error("error", error);
      }
    }

  }, [
    smartWalletAddress,
    connectedWallets
  ]);

  const handleClose = useCallback(() => {
    if (!isLoading) {
      setIsOpened(false)
      setErrorMessage('')
    }
  }, [isLoading])

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if ((event.key === 'Escape' || event.key === 'Esc') && !isLoading) {
      setIsOpened(false)
      setErrorMessage('')
    }
  }, [isLoading])

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    }
  }, [
    isOpened, 
    isLoading
  ])

  return (
    <div style={{
      position: 'relative',
      zIndex: isOpened ? 1 : 0
    }}>
      <button style={{
        padding: 0,
        border: 'none',
        background: 'transparent'
      }}
        type="button"
        onClick={() => setIsOpened(true)}
      >
        <Image src={"/assets/images/cashier/transfer.svg"}
          width={140}
          height={30}
          alt='transfer_in'
          style={{
            maxWidth: '140px'
          }}
          loading="eager"
        />
      </button>

      {
        isOpened &&
        createPortal(
          <>
            <div className="popup-overlay" onClick={handleClose}></div>
            <div className="popup-content">
              <TransferInput
                onTransfer={transferIn}
                errorMessage={errorMessage}
                isLoading={isLoading}
                isSuccess={isSuccess}
                onSuccess={() => {
                  setIsOpened(false)
                  setTransactionHash('')
                  setErrorMessage('')
                }}
                type="in"
              />
            </div>
          </>,
          document.body
        )
      }
    </div>
  )
}