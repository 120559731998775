import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { connectButtonTheme } from "@app/connect-wallet/theme";
import makeBlockie from 'ethereum-blockies-base64';  // For generating wallet avatars
import { resolveL2Name, BASENAME_RESOLVER_ADDRESS } from "thirdweb/extensions/ens";
import { client } from "@/app/connect-wallet/client";  // Correct import for client
import { base } from "thirdweb/chains";
import Image from 'next/image';
import defaultImage from "../../public/assets/images/bamboo/game-balance.svg"
import { DisconnectModal } from './DisconnectModal';

export const formatWalletAddress = (address: string) => {
  return address ? `${address.slice(0, 5)}...${address.slice(-4)}` : 'N/A';
};

const PersonalWalletButton: React.FC = () => {
  const { initialFetch, personalWalletAddress, balance: { personal: personalBalance} } = useSelector((state: RootState) => state.wallet);
  
  const [l2Name, setL2Name] = useState<string | null>(null);
  const avatarSrc = personalWalletAddress ? makeBlockie(personalWalletAddress) : '';

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const fetchL2Name = async () => {
      if (personalWalletAddress && client) {
        try {
          const name = await resolveL2Name({
            client,
            address: personalWalletAddress,
            resolverAddress: BASENAME_RESOLVER_ADDRESS,
            resolverChain: base,
          });
          if (name) {
            setL2Name(name);
          } else {
            setL2Name(null);
          }
        } catch (error) {
          console.error("Error resolving L2 name:", error);
          setL2Name(null);
        }
      }
    };

    fetchL2Name();
  }, [personalWalletAddress]);

  return (
    <div className="wallet-display">
      <button className="wallet-button" onClick={() => setIsOpened(true)}>
        <div className="avatar-container">
            <Image
              alt="avatar"
              src={avatarSrc || defaultImage}
              className="wallet-avatar"
              width={46}
              height={46}
            />
        </div>
        <div className="wallet-info">
          <span className="wallet-address">{l2Name || formatWalletAddress(personalWalletAddress ?? '')}</span>
          <span className="wallet-balance">{initialFetch ? "Loading..." : `${personalBalance} BAMBOO`}</span>
        </div>
      </button>

      <DisconnectModal isOpened={isOpened} avatarSrc={avatarSrc} handleClose={() => setIsOpened(false)} />

      <style jsx>{`
        .wallet-button {
          background-color: ${connectButtonTheme.colors.connectedButtonBg};
          border: none;
          color: ${connectButtonTheme.colors.accentButtonText};
          border-radius: 8px;
          padding: 8px;
          display: flex;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          width: 190px;
          overflow: hidden;
        }

        .wallet-button:hover {
          background-color: ${connectButtonTheme.colors.connectedButtonBgHover};
        }

        .wallet-info {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding-left: 8px;
          font-size: 12px;
          color: rgb(255, 255, 255);
          line-height: normal;
          text-align: left;
          text-wrap: inherit;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0px;
          gap: 2px;
        }

        .wallet-address {
          font-weight: 500;
          display: block;
          max-width: 100%;
          overflow: hidden;
          font-size: 18px;
          line-height: 24px;
          white-space:nowrap;
          text-overflow: ellipsis;
        }

        .wallet-balance {
          font-weight: 400;
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .avatar-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          overflow: hidden;
          flex-shrink: 0;
        }

        .wallet-avatar {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      `}</style>
    </div>
  );
};

export default PersonalWalletButton;
