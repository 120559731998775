'use client'; // Mark as a Client Component

import Link from 'next/link';
import React from 'react';
import { transferBambooEOAToAdmin } from '../actions/transferBambooEOAToAdmin'; // Import the new EOA transfer action

interface CategoryProps {
  personalWalletAddress: string | null;
  smartWalletAddress: string | null;
}

const Category: React.FC<CategoryProps> = ({ personalWalletAddress, smartWalletAddress }) => {
  // Handler for out transfer
  const handleOutTransfer = async () => {
    try {
      if (!smartWalletAddress) {
        console.error('Smart Wallet Address is not available.');
        return;
      }

      const response = await fetch('/api/blockchain/outTransfer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          smartWalletAddress: smartWalletAddress,
          amount: 2, // Sending amount as 2
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Out Transfer successful:', data);
      } else {
        console.error('Out Transfer failed:', data);
      }
    } catch (error) {
      console.error('Error during outTransfer API call:', error);
    }
  };

  // Handler for in transfer
  const handleInTransfer = async () => {
    try {
      if (!smartWalletAddress) {
        console.error('Smart Wallet Address is not available.');
        return;
      }

      const response = await fetch('/api/blockchain/inTransfer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          smartWalletAddress: smartWalletAddress,
          amount: 2, // Sending amount as 2
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('In Transfer successful:', data);
      } else {
        console.error('In Transfer failed:', data);
      }
    } catch (error) {
      console.error('Error during inTransfer API call:', error);
    }
  };

  return (
    <>
      <section className="tf-category tf-section">
        <div className="category-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="sc-heading style-2">
                <div className="content-left">
                  <div className="inner">
                    <h3>Test Buttons</h3>
                    <p className="desc">For testing calls</p>
                  </div>
                </div>
              </div>
            </div>

            {/* First Card - Out Transfer to Admin */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="sc-category wow fadeInUp">
                <div className="card-content">
                  <h5>
                    <Link href="#">Send Bamboo to Admin</Link>
                  </h5>
                  <p>Transfer 2 Bamboo tokens to Admin.</p>
                  <button onClick={handleOutTransfer}>Bet</button>
                </div>
              </div>
            </div>

            {/* Second Card - In Transfer to Smart Wallet */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="sc-category wow fadeInUp pl-19">
                <div className="card-content">
                  <h5>
                    <Link href="#">Send Bamboo to Smart Wallet</Link>
                  </h5>
                  <p>Transfer 2 Bamboo tokens to Smart Wallet.</p>
                  <button onClick={handleInTransfer}>Win</button>
                </div>
              </div>
            </div>

            {/* Original Cards - Without Modification */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="sc-category wow fadeInUp mg-bt-0">
                <div className="card-content">
                  <h5>
                    <Link href="#">4</Link>
                  </h5>
                  <p>4</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="sc-category wow fadeInUp mg-bt-0 pl-19">
                <div className="card-content">
                  <h5>
                    <Link href="#">5</Link>
                  </h5>
                  <p>5</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="sc-category wow fadeInUp mg-bt-0 pl-40 end">
                <div className="card-content">
                  <h5>
                    <Link href="#">6</Link>
                  </h5>
                  <p>6</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
