import React, { useEffect, useState } from 'react';
import Link from 'next/link';

export default function Footer1({ footercls }) {
    return (
        <>
            <footer id="footer" className={`clearfix bg-style ${footercls ? footercls : ""}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="widget-social text-center">
                                <ul className="social-icons">
                                    <li>
                                        <a
                                            aria-label="link-to-x"
                                            href="https://t.me/pandaistheplug"
                                            target="_blank"
                                            className="button is-social-button is-on-white w-inline-block"
                                        >
                                            <img src="/assets/images/social-links/x.svg" alt="link-to-x" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label="link-to-telegram"
                                            href="https://t.me/bambooonbase"
                                            target="_blank"
                                            className="button is-social-button is-on-white w-inline-block"
                                        >
                                            <img src="/assets/images/social-links/telegram.svg" alt="link-to-telegram" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label="link-to-dexscreen"
                                            href="https://dexscreener.com/base/0xfdcbf4764aeaa9a576ad975e8677270027bcd617"
                                            target="_blank"
                                            className="button is-social-button is-on-white w-inline-block"
                                        >
                                            <img src="/assets/images/social-links/dexscreener.svg" alt="link-to-dexscreener" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label="link-to-dextools"
                                            href="https://www.dextools.io/app/en/base/pair-explorer/0xfdcbf4764aeaa9a576ad975e8677270027bcd617"
                                            target="_blank"
                                            className="button is-social-button is-on-white w-inline-block"
                                        >
                                            <img src="/assets/images/social-links/dextools.svg" alt="link-to-dextools" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label="link-to-tokensniffer"
                                            href="https://tokensniffer.com/token/base/k4vdhkss5ojdvkxq1a2pk6bksfcq1hkvdkcgenbq35sxo0la6busqs42ijpz"
                                            target="_blank"
                                            className="button is-social-button is-on-white w-inline-block"
                                        >
                                            <img
                                                src="/assets/images/social-links/tokensniffer.svg"
                                                alt="link-to-tokensniffer"
                                                className="social-icon"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Bottom */}
            <div className="bottom">
                <div className="container text-center">
                    <div className="bottom-inner">
                        PandaPlinko.io 2024
                    </div>
                </div>
            </div>

            {/* CSS for styling */}
            <style jsx>{`
                .widget-social {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0;
                }

                .social-icons {
                    display: flex;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    gap: 15px; /* Space between icons */
                }

                .social-icons li {
                    display: inline-block;
                }

                .social-icon {
                    width: 30px;
                    height: 30px;
                }

                .bottom {
                    background-color: #000;
                    padding: 10px 0;
                    color: white;
                }

                .bottom-inner {
                    font-size: 14px;
                }

                .text-center {
                    text-align: center;
                }
            `}</style>
        </>
    );
}
