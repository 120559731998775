import { client } from "../../client";
import { useActiveAccount, useReadContract } from "thirdweb/react";
import { getContract } from "thirdweb";
import { chain } from "@app/connect-wallet/chain";
import { ACCOUNT_FACTORY_ADDRESS } from "@app/connect-wallet/constants";

export const useShouldSendEmptyTransaction = () => {
  const smartAccount = useActiveAccount();

  const contract = getContract({
    client,
    chain: chain,
    address: ACCOUNT_FACTORY_ADDRESS
  });
   
  const { data: isRegistered, isFetched } = useReadContract({
    contract,
    method: 'function isRegistered(address _account) returns (bool)',
    params: [smartAccount?.address!],
    queryOptions: {
      enabled: !!smartAccount?.address, // Only run if the contract is defined
    }
  });

  return { isRegistered, isFetched: isFetched }
}

