import { getContract } from "thirdweb";
import { addSessionKey } from "thirdweb/extensions/erc4337";
import { SessionKey, setSessionKey, setIsSigningSessionKey, setHasValidSessionKey } from '@/store/slices/walletSlice';
import { FORTY_FOVE_MINUTES_IM_MILLISECONDS, SIX_MONTHS_IN_MILLISECONDS, SESSION_KEY_ADDRESS } from "../../constants";
import { chain } from "../../chain";
import { client } from "../../client";
import { useDispatch, useSelector } from "react-redux";
import { useActiveAccount, useSendTransaction } from "thirdweb/react";
import { RootState } from "@utils/redux/store";

export const useSessionKeyManager = () => {
  const dispatch = useDispatch();
  const smartAccount = useActiveAccount();
  const { smartWalletAddress  } = useSelector((state: RootState) => (state.wallet));
  const { mutateAsync: sendTransaction } = useSendTransaction();
 

  const checkExistingSessionKey = async (): Promise<SessionKey | null> => {
    try {
      const res = await fetch('/api/blockchain/account/sessions/check-all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chain: chain.id,  // Send only the chain ID
          contractAddress: smartWalletAddress,  // Send the smart wallet address
        }),
      });
  
      const data = await res.json();
  
      if (data?.result) {
        const now = new Date();
  
        for (const session of data.result) {
          const expirationDate = new Date(session.expirationDate);
          const remainingTime = expirationDate.getTime() - now.getTime();
          const isNotExpired = remainingTime > 0;
          const hasAtLeast45Minutes = remainingTime >= FORTY_FOVE_MINUTES_IM_MILLISECONDS;
  
          // Set the hasValidSessionKey based on remaining validity
          if (hasAtLeast45Minutes) {
            dispatch(setHasValidSessionKey(true));  // Set hasValidSessionKey to true if valid
          } else {
            dispatch(setHasValidSessionKey(false));  // Set to false if not enough time left
          }
  
          if (
            session.signerAddress.toLowerCase() === SESSION_KEY_ADDRESS.toLowerCase() &&
            isNotExpired &&
            hasAtLeast45Minutes
          ) {
            // Return a valid SessionKey object
            return {
              signerAddress: session.signerAddress,
              expirationDate: session.expirationDate,
              approvedCallTargets: session.approvedCallTargets || [],
              nativeTokenLimitPerTransaction: session.nativeTokenLimitPerTransaction || "0",
              startDate: session.startDate,
            };
          }
        }
      }
  
      // If no valid session key is found, set hasValidSessionKey to false
      dispatch(setHasValidSessionKey(false));
      return null;  // No valid session key found
    } catch (error) {
      console.error('Error checking for existing session key:', error);
      dispatch(setHasValidSessionKey(false));  // Set to false in case of error
      return null;
    }
  };
  
  const handleSessionKeyCreation = async () => {
    try {
      const contract = getContract({
        address: smartWalletAddress!,
        chain,
        client,
      });
    
      dispatch(setIsSigningSessionKey(true));
  
      const existingSessionKey = await checkExistingSessionKey(); // Pass dispatch here
  
      if (existingSessionKey) {
        dispatch(setSessionKey(existingSessionKey));
        dispatch(setIsSigningSessionKey(false));
        return;
      }
  
      if (!smartAccount) {
        dispatch(setIsSigningSessionKey(false));
        return;
      }
  
      const transaction = addSessionKey({
        contract,
        account: smartAccount,
        sessionKeyAddress: SESSION_KEY_ADDRESS,
        permissions: {
          approvedTargets: "*",
          nativeTokenLimitPerTransaction: 0.1,
          permissionStartTimestamp: new Date(),
          permissionEndTimestamp: new Date(Date.now() + SIX_MONTHS_IN_MILLISECONDS) ,  // 6 months validity
        },
      });
  
      await sendTransaction(transaction, { onSuccess: async () => {
        console.log('Send transaction - add session key')

        let retryCount = 0;
        let interval: NodeJS.Timeout | undefined;

        const addNewSessionKey = async () => {
          const newSessionKey = await checkExistingSessionKey(); // Check the newly created session key
  
          if (newSessionKey) {
            dispatch(setSessionKey(newSessionKey));
            clearInterval(interval);
            return;
          }

          console.error("Failed to retrieve session key.");
          dispatch(setSessionKey(null));

          retryCount += 1;
          if (retryCount >= 3) {
            clearInterval(interval);
            console.error("Max retry new session key attempts reached.");
          }
        }

        interval = setInterval(addNewSessionKey, 3000);
        await addNewSessionKey();

        dispatch(setIsSigningSessionKey(false));
      }});
    } catch (error) {
      console.error("Error creating session key:", error);
      dispatch(setIsSigningSessionKey(false));
    }
  };
  
  return { checkExistingSessionKey, handleSessionKeyCreation }
}

