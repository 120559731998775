import React, {useEffect, useRef } from 'react';
import Link from 'next/link';
import { ConnectTWButton } from '../../elements/ConnectButton';
import PersonalWalletButton from '../../elements/PersonalWalletButton';

// Import Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { SwapWidget } from '@components/elements/SwapWidget';
import useDetectOS from '@app/connect-wallet/utils/hooks/useDetectOS';
import { CashOutButton } from '@components/elements/CashOutButton';
import { TransferInButton } from '@components/elements/TransferInButton';

const Header1: React.FC = () => {
  // Accessing the state directly from Redux store
  const smartWalletAddress = useSelector((state: RootState) => state.wallet.smartWalletAddress);
  const { isMobile, isDisconnecting } = useSelector((state: RootState) => state.ui);
  const deviceType = useDetectOS();
  const refHeader = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (refHeader.current) {
      const scrollPosition = window.scrollY;

      refHeader.current.classList.toggle('is-fixed', scrollPosition > 50);
      refHeader.current.classList.toggle('is-small', scrollPosition > 50);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const applyCustomOverlayBackground = () => {
      // Select the modal overlay element based on the rgba color you set in modalOverlayBg
      const modalOverlay = Array.from(document.querySelectorAll('div')).find(
        (el) => getComputedStyle(el).backgroundColor === 'rgba(0, 0, 0, 0.15)'
      );

      if (modalOverlay) {
        // Apply the gradient and SVG background
        modalOverlay.style.background = `
          radial-gradient(circle, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.35) 65%, rgba(0, 0, 0, 0) 100%),
          url('/assets/images/sunburst/sunburst.svg') no-repeat center/cover
        `;
        modalOverlay.style.backgroundSize = 'cover';
      }
    };

    // Call initially to apply background when modal appears
    applyCustomOverlayBackground();

    // Observer to watch for new overlays appearing (when the modal is re-rendered)
    const observer = new MutationObserver(() => {
      applyCustomOverlayBackground();
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      {/* 30px gradient bar */}
      <div className="gradient-bar">
        <div className="gradient-bar-content">
          {/* Left-aligned text */}
          <div className="left-text">
            Unlock your digital freedom
          </div>

          {/* Right-aligned buttons */}
          <div className="app-buttons">
            {/* Open Wallet button */}
            <a href="https://www.openwallet.com" target="_blank" rel="noopener noreferrer">
              <img
                src="/assets/images/download/openwallet.png"
                alt="Open Wallet"
                className="store-button"
              />
            </a>

            {/* App Store button */}
            <a href="https://www.openwallet.com" target="_blank" rel="noopener noreferrer" className="app-store-button">
              <img
                src="/assets/images/download/app-store.png"
                alt="Download from App Store"
                className="store-button app-logo"
              />
              <img
                src={`/assets/icon/${deviceType}-logo.png`}
                alt={`${deviceType} Logo`}
                className="store-button app-icon"
              />
            </a>

            {/* Google Play button */}
            <a href="https://www.openwallet.com" target="_blank" rel="noopener noreferrer" className="google-play-button">
              <img
                src="/assets/images/download/google-play.png"
                alt="Download from Play Store"
                className="store-button app-logo"
              />
            </a>
          </div>
        </div>
      </div>


      <header ref={refHeader} id="header_main" className={`header_1 js-header`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="d-block d-lg-none">
                {/* Mobile Menu Button */}
              </div>
              <div id="site-header-inner" className="flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link href="/" rel="home" className="main-logo">
                      <img id="logo_header" src="/assets/images/logo/panda-plinko.webp" alt="panda-plinko" width={151} height={45} />
                    </Link>
                  </div>
                </div>

                {/* Wallet Section */}
                <div className={`wallet-container ${isMobile && 'mr-24'}`}>
                  {smartWalletAddress && !isDisconnecting && <SwapWidget />}
                  {!isDisconnecting && <div className={`wallet-container ${isMobile && smartWalletAddress ? 'hidden' : ''}`}>
                    <div className="wallet-grid">
                      <div><ConnectTWButton /></div>
                      {
                        smartWalletAddress ? (
                          <>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  gap: '6px',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <TransferInButton />
                                <CashOutButton />
                              </div>
                            </div>
                            <div><PersonalWalletButton /></div>
                          </>
                        ) : (
                          <>
                            {/* Empty placeholders to maintain grid layout */}
                            <div></div>
                            <div className="empty"></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </>
                        )}
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile View: Wallet Drawer */}
      {(!isDisconnecting && isMobile && smartWalletAddress) && (
        <div className="wallet-drawer">
          <div className="wallet-grid">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '6px',
                alignItems: 'center',
              }}>
              <CashOutButton />
              <ConnectTWButton />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '6px',
                alignItems: 'center',
              }}>
              <TransferInButton />
              <PersonalWalletButton />
            </div> {/* Render the Connect Button here for mobile */}</div>
        </div>
      )}

      {/* CSS Styles for the new wallet section */}
      <style jsx>{`
        .mr-24 {
          margin-right: 16px;
        }

        .wallet-container {
          display: flex;
          gap: 16px;
          align-items: center;
        }
        
        .wallet-section {
          border: 2px solid darkgreen;
          border-radius: 8px;
          padding: 5px;
          display: inline-block;
          background-color: darkgreen;
          margin-bottom: 8px;
        }
        
        .wallet-grid {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
        }

        .label {
          font-weight: bold;
          text-align: center;
          color: white;
        }

        .empty {
          /* Just to occupy space */
        }

        .wallet-grid > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        /* Mobile drawer specific styles */
        .wallet-drawer {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: darkgreen;
          padding: 10px;
          border-top: 2px solid darkgreen;
          z-index: 9999;
        }

        .hidden {
          display: none;
        }
        
 .gradient-bar {
  height: 50px;
  width: 100%;
  background: linear-gradient(
    to right, 
    #d0e500 35%,     /* Fully green until 35% */
    black 65%      /* Fully black from 65% onward */
  );
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-bar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  padding: 0 20px; /* Padding for the content inside the bar */
  align-items: center;
}

.left-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.app-buttons {
  display: flex;
  gap: 10px; /* Space between the buttons */
  justify-content: flex-end;
  align-items: center;
}

.store-button {
  height: 30px;
  width: auto; /* Maintain aspect ratio */
}

.app-buttons a:first-child {
  margin-right: 30px; /* 30px space to the left of the Open Wallet button */
}

/* Hide alternative icons by default */
.app-icon {
  display: none;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .left-text {
    font-size: 12px; /* Slightly reduce text size */
  }

  .app-logo {
    display: none; /* Hide full logos */
  }

  .app-icon {
    display: block; /* Show small Apple/Android logos */
    height: 30px; /* Adjust as needed */
    width: auto; /* Maintain aspect ratio */
  }
}

/* Header styles for fixed position at the top */
header#header_main {
  position: fixed;
  top: 50px; /* Adjust if you want to account for the gradient bar */
  width: 100%;
  background-color: rgba(0, 128, 0, 0); /* Medium green with 30% opacity */
  z-index: 1000; /* Ensure it sits above other content */
  padding: 10px 0;
}

.header_1.is-fixed {
  /* Add any other styles you'd like for the fixed state */
  transition: background-color 0.3s ease-in-out;
}
      `}</style>
    </>
  );
};

export default Header1;
