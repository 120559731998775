// walletAssignmentManager.ts
import { setSmartWalletAddress, setPersonalWalletAddress, setPersonalWalletType } from "@utils/redux/slices/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { getContract } from "thirdweb";
import { getAllAdmins } from "thirdweb/extensions/erc4337";
import { client } from "../../client";
import { chain } from "../../chain";
import { RootState } from "@utils/redux/store";
import { useConnectedWallets } from "thirdweb/react";

const maxRetries: number = 5;  // Optional: Max number of retry attempts
const retryDelay: number = 5000; // Retry interval in milliseconds (5 seconds)

export const useWalletAssignment = () => {
  const dispatch = useDispatch();
  const connectedWallets = useConnectedWallets();
  const { smartWalletAddress  } = useSelector((state: RootState) => (state.wallet));

  const assignWallets = async () => {
    if (!smartWalletAddress) return;

    let retryCount = 0;
    let interval: NodeJS.Timeout | undefined;

    const fetchAdminsAndAssignWallets = async () => {
      try {
        const contract = getContract({
          client,
          chain,
          address: smartWalletAddress,
          abi: [
            {
              inputs: [],
              name: "getAllAdmins",
              outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
              stateMutability: "view",
              type: "function",
            },
          ],
        });

        // Fetch admin addresses using getAllAdmins
        const adminAddresses = await getAllAdmins({ contract });
        // Retry mechanism if admin addresses are not found
        if (adminAddresses.length > 0) {
          const adminWallet = adminAddresses[0];
          let foundSmartWallet = false;
          let foundPersonalWallet = false;

          // Iterate over connected wallets and assign smart and personal wallets

          connectedWallets.forEach(async (wallet) => {
            const account = wallet.getAccount();

            if (!account || !account.address) return;

            if (wallet.id === "smart" && !foundSmartWallet) {
              dispatch(setSmartWalletAddress(account.address));
              foundSmartWallet = true;
              return;
            }

            if (account.address.toLowerCase() === adminWallet.toLowerCase() && !foundPersonalWallet) {
              dispatch(setPersonalWalletAddress(adminWallet));
              dispatch(setPersonalWalletType(wallet.id));
              foundPersonalWallet = true;
            }
          });

          // Stop retrying if both wallets are found
          if (foundSmartWallet && foundPersonalWallet) {
            clearInterval(interval);
            console.log("Admin wallets successfully assigned.");
            return;
          }
        }

        console.error("No admins found, retrying...");
      } catch (error) {
        console.error("Error fetching admin wallet or assigning connected wallets", error);
      }

      retryCount += 1;
      if (retryCount >= maxRetries) {
        clearInterval(interval);
        console.error("Max retry attempts reached.");
      }
    };

    // Retry fetching admins and assigning wallets every `retryDelay` milliseconds
    interval = setInterval(fetchAdminsAndAssignWallets, retryDelay);

    // Call it once immediately
    await fetchAdminsAndAssignWallets();
  };

  return { assignWallets }
}
