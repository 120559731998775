
import { chain } from "../../chain";
import { client } from "../../client";
import { useSelector } from "react-redux";
import { useActiveAccount, useSendTransaction } from "thirdweb/react";
import { RootState } from "@utils/redux/store";
import { useWalletAssignment } from "./useWalletAssignment";
import { useHandleDisconnect } from "./useHandleDisconnect";

export const useSendEmptyTransaction = () => {
  const { mutateAsync: sendTransaction } = useSendTransaction();
  const smartAccount = useActiveAccount();
  const { assignWallets } = useWalletAssignment();
  const { smartWalletAddress  } = useSelector((state: RootState) => (state.wallet));
  const { handleDisconnect } = useHandleDisconnect();
  
    /**
   * Sends an empty transaction to the specified smart wallet address.
   * This function is used to initialize the connection with the smart wallet.
   * @param smartWalletAddress The address of the smart wallet to send the transaction to.
   */
  const sendEmptyTransaction = async () => {
    if (!smartAccount) return;

    try {
      const transaction = {
        to: smartWalletAddress!,  // Send to the smart wallet address itself
        value: BigInt(0),  // Empty transaction, set value as bigint
        data: "0x" as `0x${string}`,  // Properly typed empty data
        chain,
        client,
      };

      await sendTransaction(transaction, {
        onSuccess: async (tx) => {
          console.log('Empty transaction successful! Assigning wallets in action...');
          
          await assignWallets();
        },
        onError: async (err) => {
          console.error('Transaction failed:', err);
          await handleDisconnect();
        },
      });
    } catch (error) {
      console.error('Error sending empty transaction:', error);
    }
  };
  
  return { sendEmptyTransaction }
}

