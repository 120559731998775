'use client'
import Link from "next/link"
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    navigation: {
        clickable: false,
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: false,
    },
    slidesPerView: 1,
    loop: false,
    speed: 500,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
}

export default function Slider1() {
    return (
        <section className="tf-slider">
            <div className="swiper-container slider">
                <Swiper {...swiperOptions} className="swiper-wrapper">
                    <SwiperSlide>
                        <div className="slider-item">
                            <div className="slider-inner">
                                <div className="slider-content">
                                    <h1 className="heading">The home of betting on $BAMBOO</h1>
                                    <div className="button-slider">
                                        <Link href="#panda-plinko" className="sc-button btn-bordered-white style letter">
                                            <span>Play Now</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="slider-img">
                                    <img src="/assets/images/slider/game_logo.png" alt="Panda Image" className="panda-image" />
                                </div>
                            </div>
                        </div>{/* item */}
                    </SwiperSlide>
                </Swiper>
                <div className="swiper-pagination" />
                <div className="swiper-button-next btn-slide-next" />
                <div className="swiper-button-prev btn-slide-prev" />
            </div>
        </section>
    )
}
