import { ethers } from "ethers";

export const switchToChainId = async (chainId: number) => {
    if (window?.ethereum) {
        await window?.ethereum?.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexValue(chainId) }],
        });
    } else {
        console.error("An ethereum provider is not installed");
    }
}
