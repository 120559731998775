import { base } from "thirdweb/chains"; // Import the correct chain object

export const detailsModal = {
  hideBuyFunds: true,
  hideSendFunds: false,
  payOptions: {
    prefillBuy: {
      token: {
        address: "0x689644b86075ed61c647596862c7403e1c474dbf",
        name: "Bamboo on Base",
        symbol: "BAMBOO",
        icon: "https://assets.coingecko.com/coins/images/39126/standard/Bamboo_Logo_800x800.png?1720656497",
      },
      chain: base, // Use the correct chain object instead of the chain ID
      allowEdits: {
        amount: true,  // allow editing the amount
        token: false,  // disable selecting the token
        chain: false,  // disable selecting the chain
      },
    },
  },
};
