import { client } from '@app/connect-wallet/client';
import { getContract } from "thirdweb";
import { base } from 'thirdweb/chains';

export const TOKEN_BAMBOO = {
    address: '0x689644b86075ed61c647596862c7403e1c474dbf',
    chainId: 8453,
    decimals: 18,
    name: 'Bamboo on Base',
    symbol: 'BAMBOO',
    logoURI: 'https://assets.coingecko.com/coins/images/39126/standard/Bamboo_Logo_800x800.png?1720656497',
    contract: getContract({
        address: "0x689644B86075ed61C647596862c7403e1c474dbf",
        chain: base,
        client,
    })
}