'use client';
import { useEffect, useState } from 'react';

interface BackToTopProps {
  target: string;
}

export default function BackToTop({ target }: BackToTopProps) {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setHasScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClick = () => {
    const element = document.querySelector(target) as HTMLElement | null;
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {hasScrolled && (
        <a id="scroll-top" className={'show'} onClick={handleClick} />
      )}
    </>
  );
}
