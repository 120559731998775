import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

interface LoggedInProps {
  onPlayNow: () => void;  // Call the handlePlayNowClick function
  isGameLoading: boolean;
}

const LoggedIn: React.FC<LoggedInProps> = ({ onPlayNow , isGameLoading}) => {
  // Load the balances directly from Redux
  const { initialFetch, balance: {personal: personalBalance, smart: smartBalance} } = useSelector((state: RootState) => state.wallet);

  return (
    <div className="new-letter-inner style-2 flex wow">
      <div className="overlay">
        <h2>Welcome! You are logged in.</h2>
        {initialFetch ? 
        <p style={{margin: '5px'}}>Loading balances...</p> 
        : <>
            <p>Gameplay Balance: {smartBalance ?? 0} BAMBOO</p>
            <p>Personal Wallet Balance: {personalBalance ?? 0} BAMBOO</p>
          </>
        }
        
        <button onClick={onPlayNow} className="blue-button" disabled={isGameLoading}>
          {isGameLoading ? " Loading..." : "Start Game"}
        </button>
      </div>
    </div>
  );
};

export default LoggedIn;
