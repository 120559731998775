'use client';

import React, { useState, useRef, useEffect, useCallback } from 'react';

import { RootState } from '@/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsReadyToPlay, setIframeSrc, setRefetchGameState } from '@/store/slices/uiSlice';

import { useActiveAccount } from 'thirdweb/react';

import LoggedOut from '@/components/elements/gameframe/LoggedOut';
import LoggedIn from '@/components/elements/gameframe/LoggedIn';
import SigningKey from '@/components/elements/gameframe/SigningKey';
import GameLauncher from '@/components/elements/gameframe/GameLauncher';

const GameFrame: React.FC = () => {
  const dispatch = useDispatch();

  // Get relevant values from Redux store
  const { smartWalletAddress, hasValidSessionKey, balance } = useSelector((state: RootState) => state.wallet);
  const { isReadyToPlay, isMobile, isDisconnecting, iframeSrc, refetchGameState } = useSelector((state: RootState) => state.ui);
  const token = useSelector((state: RootState) => state.auth.token);

  const [isFullscreen, setIsFullscreen] = useState(false); // Fullscreen state
  const [isLoading, setIsLoading] = useState(false);
  const [hasGameStarted, setHasGameStarted] = useState(false);

  const smartAccount = useActiveAccount();
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Function to toggle fullscreen mode
  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  // PlayNow button action to fetch game URL after session key has been validated
  const handlePlayNowClick = async () => {
    if (!smartAccount) return;

    // Get user's language (ISO 639-1, 2-letter code)
    const language = navigator.language.split('-')[0]; // Extract the first part (e.g., 'en' from 'en-US')

    // Default IP and country values if they cannot be determined
    let ipAddress = '192.168.0.1';  // Default to a local IP if unknown
    let country = 'MZ';  // Default to Mozambique if unknown

    setIsLoading(true);

    // Fetch game URL after PlayNow is clicked
    if (!iframeSrc && smartWalletAddress && balance.smart !== null && hasValidSessionKey) {
      try {
        const multipliedBalance = balance.smart * 100000;

        const response = await fetch('/api/hub88/game/url', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Pass JWT in the Authorization header
          },
          body: JSON.stringify({
            sessionKey: hasValidSessionKey, // Use valid session key
            user: smartWalletAddress,
            balance: multipliedBalance,
            isMobile,
            ipAddress,
            country,
            lang: language,
          }),
        });

        const data = await response.json();
        if (data?.data?.url) {
          dispatch(setIframeSrc(data.data.url));
          dispatch(setIsReadyToPlay(true)); // Move to the game launcher state
        }
      } catch (error) {
        console.error('Error fetching game URL:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePlay = () => {
    handlePlayNowClick();
    setHasGameStarted(true);
  }
  
  useEffect(() => {
    if (refetchGameState) {
      dispatch(setIframeSrc(''));
    }
  }, [refetchGameState]);

  useEffect(() => {
    if (refetchGameState && hasGameStarted && !iframeSrc) {
      handlePlayNowClick();

      dispatch(setRefetchGameState(false));
    }
  }, [refetchGameState, iframeSrc, hasGameStarted]);

  
  if (isDisconnecting) {
    return (
      <div className="new-letter-inner style-2 flex wow">
        <div className="overlay" style={{
          wordBreak: 'break-word'
        }}>
          <h2>Disconnecting wallet...</h2>
        </div>
      </div>
    );
  }

  // State 1: User is logged out
  if (!smartWalletAddress) {
    return (
      <div className="container">
        <LoggedOut  />
      </div>
    );
  }

  // State 2: Signing the session key (triggered if hasValidSessionKey is false)
  if (smartWalletAddress && !hasValidSessionKey) {
    return (
      <div className="container">
        <SigningKey isSigning={!hasValidSessionKey} />
      </div>
    );
  }

  // State 3: Logged in state, session key validated, but game not launched yet
  if (smartWalletAddress && hasValidSessionKey && !isReadyToPlay) {
    return (
      <div className="container">
        <LoggedIn onPlayNow={handlePlay} isGameLoading={isLoading}/>
      </div>
    );
  }

  // State 4: GameLauncher once game URL is fetched
  if (isReadyToPlay && iframeSrc) {
    return (
      <>
        {/* Fullscreen overlay if enabled */}
        {isFullscreen && <div className="modal-overlay" />}

        <div className={`gameplay-modal ${isFullscreen ? 'fullscreen-modal' : ''}`}>
          <GameLauncher
            iframeSrc={iframeSrc}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}  // Pass the toggleFullscreen function
            containerRef={containerRef}
          />
        </div>
      </>
    );
  }

  return <div className="new-letter-inner style-2 flex wow" />; // Default fallback in case no state is hit
};

export default GameFrame;